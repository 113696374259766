import React, { Fragment } from "react";

import IncidentReportForm from "../../../components/Admin/IncidentReports/IncidentReportForm/IncidentReportForm";
import SEO from "../../../layout/Seo";

const AddIncidentReport = () => {
  return (
    <Fragment>
      <SEO title="Add New Incident Report" />
      <IncidentReportForm activeReport={{}} />
    </Fragment>
  );
};

export default AddIncidentReport;
